html,
body,
#root {
  margin: 0;
  height: 100vh;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#map-wrapper,
#map {
  position: relative !important;
  display: flex;
  flex: 1;
}

#view-default-view {
  position: absolute;
  bottom: 0;
  left: 0;
  top: unset !important;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

.bg-login {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../public/assets/bg-login.jpeg);
}
.bg-warning {
  background-color: #f0ad4e;
}
.bg-success {
  background-color: #5cb85c;
}
.bg-error {
  background-color: #d9534f;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.h-1-2 {
  height: 50%;
}
.h-1-3 {
  height: 33%;
}
.h-1-4 {
  height: 25%;
}
.h-9-10 {
  height: 90%;
}
.h-1-10 {
  height: 10%;
}
.h-8-10 {
  height: 80%;
}
.h-2-10 {
  height: 20%;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.flex-1 {
  flex: 1 1 0%;
}
.z-1 {
  z-index: 1;
}
.justify-end {
  justify-content: flex-end;
}
.justify-evenly {
  justify-content: space-evenly;
}
.items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.bottom-16 {
  bottom: 16px;
}
.right-16 {
  right: 16px;
}
.gap-16 {
  gap: 16px;
}
.p-8 {
  padding: 8px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-10 {
  margin: 10px;
}
.m-16 {
  margin: 16px;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-128 {
  margin-bottom: 128px;
}
.wrap {
  flex-wrap: wrap;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.border {
  border-width: 1px;
}
.border-top-white {
  border-top: 1px solid white;
}
.border-solid {
  border-style: solid;
}
.border-blue {
  border-color: #337ab7;
}
.text-blue {
  color: #337ab7;
}
.text-red {
  color: red;
}
.text-white {
  color: #ffffff;
}
.bg-primary {
  background-color: #222;
}
.bg-white {
  background-color: #ffffff;
}
.h-50 {
  height: 50px;
}
.h-100 {
  height: 100px;
}
.h-150 {
  height: 150px;
}
.h-320 {
  height: 320px;
}
.min-w-500 {
  min-width: 500px;
}
.max-w-350 {
  max-width: 350px;
}
.w-415 {
  width: 415px;
}
.w-450 {
  width: 450px;
}
.font-bold {
  font-weight: bold;
}
.font-x-large {
  font-size: x-large;
}
.font-medium {
  font-size: medium;
}
.text-center {
  text-align: center;
}
.rounded-2 {
  border-radius: 2%;
}

.mapButton {
  display: flex;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  color: black;
  border: 2px solid black;
  border-radius: 20%;
  width: 48px;
  height: 48px;
  margin: 8px;
}

.mapboxgl-popup-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  border: 1px solid whitesmoke;
  z-index: 30;
  border-radius: 5%;
  width: 150px;
  padding: 8px;
  font-size: smaller;
}
